class Lightbox {
    constructor(el, Modal, Carousel, mode = 'gallery') {
        this.el = $(el);
        this.modal = Modal;
        this.carousel = Carousel;
        this.mode = mode;
        console.log('ICI ' + mode)
        if(this.el.data('gallery')){

            $('#myModal-carousel').remove();
            let contentCarousel = this.contentCarousel(this.el.data('index'));
            
            let htmlMdal = this.createModal(contentCarousel);
            if(htmlMdal)
                $( 'body' ).append( htmlMdal );

            let hImg = $( window ).height() - 50;
            hImg += 'px';
            if($('#myModal-carousel img').attr('style', 'max-height: ' + hImg + '; max-width: 1200px'))
            
            var myModal = new this.modal($('#myModal-carousel'), {
                keyboard: false
            })

            myModal.show();
        } 
        else{
            let contentImg = this.contentImg();
            let htmlMdal = this.createModal(contentImg);
            if(htmlMdal)
                $( 'body' ).append( htmlMdal );
            
            var myModal = new this.modal($('#myModal-' + this.el.data('index')), {
                keyboard: false
            })

            myModal.show();
        }
        
    }

    createModal($content){
        if( $('.modal').attr('id') == 'myModal-' + this.el.data('index')){
            return false;
        }
        let idModal = '';
        if(this.el.data('gallery'))
            idModal = 'carousel';
        else
            idModal = this.el.data('index');
            
        let html_modal = `
        <div class="modal fade" id="myModal-` + idModal + `" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                ` + $content + `
            </div>
            </div>
        </div>
        </div>
        `
        return html_modal;
    }

    contentImg(){
        if(this.el.data('href'))
            return `<img src="` + this.el.attr('data-href') + `" class="img-fluid"></img>`;

        return false;
    }

    contentCarousel(i){

        let retHtml = ``;
        
        retHtml +=
        `<div id="modalCarousel" class="carousel slide h-100" data-bs-touch="false" data-bs-interval="false">
            <div class="carousel-inner d-flex h-100">`

            if( this.mode == 'gallery'){
                $('.lightbox .col a').each(function(){
                    retHtml += `
                    <div class="carousel-item align-self-center `+ ($(this).data('index') == i ? 'active' : '' ) +`">
                        <img src="` + $(this).data('href') + `" class="img img-fluid" alt="test">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>` + $(this).data('caption') + `</h5>
                        </div>
                    </div>
                    `
                })
            }
            else if( this.mode == 'slider'){
                let active = false;
                let c = 0
                $('.lightbox .item .overflow-hidden').each(function(){
                    
                    if ($(this).data('index') == i && !active && c == 0) {
                        active = true;
                        c ++;
                    }
                    else{
                        active = false;
                    }

                    console.log($(this).parent().parent().parent().attr('class'), $(this).data('index'), i, active);
                    retHtml += `
                    <div class="carousel-item align-self-center `+ (active ? 'active' : '' ) +`">
                        <img src="` + $(this).data('href') + `" class="img img-fluid" alt="test">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>` + $(this).data('caption') + `</h5>
                        </div>
                    </div>
                        `
                })
            }
            

        retHtml +=
           `</div>
            <button class="carousel-control-prev" type="button" data-bs-target="#modalCarousel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#modalCarousel" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>`

        return retHtml;
    }

}

export default Lightbox;