/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
const $ = require('jquery');
global.$ = global.jQuery = $;

import { Modal, Carousel } from 'bootstrap';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import Alert from 'bootstrap/js/dist/alert';

import 'aos/dist/aos.css';
import './styles/includes/css/cookieconsent.css';
import Lightbox from './styles/includes/js/lightbox';

import { tns } from '../node_modules/tiny-slider/src/tiny-slider';
import '../node_modules/tiny-slider/src/tiny-slider.scss';
let velosQuery = get_query();

if (3 in velosQuery && 
    ( 
    velosQuery[4] == 'location-velos-vtt-rosalies' || 
    velosQuery[4] == 'location-velos-vtt-rosalies#topFeature' || 
    velosQuery[4] == 'location-velos-vtt-rosalies#subFeature' || 
    velosQuery[4] == 'location-velos-vtt-rosalies#utility' 
    ) 
    ) 
{

    var slider = tns({
        container: '.slider-velos .slider',
        items: 4,
        slideBy: 'page',
        autoplay: true,
        nav: false,
        controls: false,
        autoplayButtonOutput: false,
        responsive: {
            300: {
                edgePadding: 20,
                gutter: 0,
                items: 1
            },
            640: {
                edgePadding: 20,
                gutter: 20,
                items: 2
            },
            700: {
                gutter: 30
            },
            900: {
                gutter: 10,
                items: 4
            }
        }
    });
}

setTimeout(() => {
    $('.slider-velos a').each(function () {
        $(this).click(function () {
            const lightbox = new Lightbox($(this), Modal, Carousel, 'slider');
            return false;
        });
    })

    $('.lightbox.imgs a').each(function () {
        $(this).click(function () {
            const lightbox = new Lightbox($(this), Modal, Carousel);
            return false;
        });
    })
}, 1000);


import Popup from './styles/includes/js/popup';
const popup = new Popup(Modal)

import './styles/includes/js/stickymenu';

const simpleParallax = require('simple-parallax-js');
let image = document.getElementsByClassName('img-parallax');
new simpleParallax(image, {
    delay: .6,
    transition: 'cubic-bezier(0,0,0,1)',
    scale: 1.5,
    orientation: 'up right'
});

$('.feature-link .row .col').each(function(){
    let target = $('a', this).attr('href');
    $(this).on('click', function() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(target).offset().top -100
        }, 100);
    });
});

function get_query() {
    var url = document.location.href;
    var qs = url.split('\/');

    return qs;
}



